import React from "react";

import {
	ErrorUI,
	Layout
} from "../components";

const NotFoundPage = ({ location }) => {
	return (
		<Layout 
			location={location} 
			title={"404 - Page Not Found"}
		>
			<ErrorUI 
				content={"Sorry, The page you are looking does not exist :("}
				title={"Not Found"}
			/>
		</Layout>
	)
}
export default NotFoundPage;